body {
    overflow-x: hidden;
    background-color: #F7FAFC;
}

img {
    width: 5rem;
    border-radius: 5%;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -20rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    border-radius: 12px;
    margin-left: 13px !important;
    margin-top: 12px !important;  
}

.container-fluid{
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 10px;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 17rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}


.list-group-flush{
    border-radius: 12px;
}

.list-group-item-action:hover, .list-group-item-action:focus  {
    background-color:#fff !important
  }

@media (max-width: 768px) {
    #page-content-wrapper .container-fluid{
        padding-left: 70px;
    }
}
@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -17rem;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

.selected {
    font-weight: 600;
}
.log{
    background-color:#F7FAFC;
}
