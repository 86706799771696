/* Style the show record entry */
.show-records {
  color: #333;
  font-size: large;
  font-family: "Product Sans Regular", sans-serif;
}

.formRow > .inputt {
  width: 35%;
  margin-right: 10px;
}
/* Ensure that the last column doesn't have extra margin */
.formRow > .inputt:last-child {
  margin-right: 0;
}
/* Styling for actions column */
.actions {
  background-color: rgb(241, 107, 129);
  color: black;
  border-radius: 10px;
}

/* Styling for edit and trash icons */
.fa-edit,
.fa-trash {
  background-color: rgb(241, 107, 129);
  color: black;
}

/* Styling for title and subtitle */
.title {
  color: black;
  display: inline-block;
  font-family: "Product Sans Regular", sans-serif;
  margin-top: 2rem;
}
.date {
  justify-content: center;
  font-size: x-large;
  font-weight: bolder;
  margin-left: 40%;
  font-family: "Product Sans Regular", sans-serif;
  margin-top: 0%;
}
.add {
  background-color: #fff;
  border-color: rgb(122, 199, 155);
  border-radius: 4px;
  color: rgb(122, 199, 155);
  font-size: 20px;
  width: auto;
  height: auto;
  /* font-family: 'Product Sans Regular', sans-serif; */
  margin-left: 85%;
  margin-top: 0%;
  display: inline-block;
  word-wrap: break-word;
}

.adds {
  background-color: #fff;
  border-color: rgb(122, 199, 155);
  border-radius: 4px;
  color: rgb(122, 199, 155);
  font-size: 20px;
  width: auto;
  height: auto;
  word-wrap: break-word;
  padding: 0.2em 0.2em;
  margin-bottom: -10%;
  display: inline-block;
  margin-right: 0.3rem;
  margin-top: 5%;
}

.add:hover {
  background-color: rgb(122, 199, 155);

  color: #fff;
}
.adds:hover {
  background-color: rgb(122, 199, 155);

  color: #fff;
}
.table-top {
  background-color: transparent;
  color: #000;
  border-radius: 10px;
  margin-right: 0.333em;
  padding: 0.5em 1em;
  font-size: large;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: -10%;
  border: 1px solid #999;
  font-family: "Product Sans Regular", sans-serif;
  width: 5rem;
  display: inline-block;
}

.toggle {
  margin-left: 20rem;
  background-color: rgb(122, 199, 155);
  background-image: none;
  padding: 7px 10px;
  font-family: fontAwesome;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 3rem;
  height: 3.5rem;
  width: 3rem;
}

.table thead th {
  background-color: #f2f2f2;
  border-radius: 6rem;
  color: #000;
}

.table td {
  color: #000;
  font-size: larger;
}

.form-control {
  display: inline-block;
  width: 140%;
  height: 3.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: -10rem;
  margin-left: -70%;
}
.form-controling{
  display: inline-block;
  width: 140%;
  height: 3.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: -70%;
}

.input-group-text {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  border-radius: 3rem;
  font-size: large;
}

.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  height: 40px;
}

.input-group:not(.has-validation) > .form-control:not(:first-child) {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
}

.input-group {
  position: relative;
  display: inline-block;
  margin-left: 90%;
  margin-bottom: 0%;
}

.form-controls {
  width: 67%;
  border-radius: 10px;
  color: rgb(79, 79, 79);
  border-color: rgba(99, 101, 102, 0.584);
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.textForm {
  font-size: x-large;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: #000;
}

.add-btn {
  color: #000;
  background-color: #dd687b;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  box-sizing: border-box;
}

.close-btn {
  color: #000;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  box-sizing: border-box;
}

/* .modal-headers .modal-title {
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-top: 2rem;
  text-align: center;
} */

.modal-headers {
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 2rem;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-content {
  position: relative;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  text-align: center;
}
.modals{
  
}


.add-cont {
  text-align: right;
}
.table-foot {
  font-size: large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Pacifico", cursive;
  font-weight: 400;
}
.pull-right {
  border-radius: 10px;
}
.page-item {
  background-color: #46ac46;
}
.container-fluid {
  margin-right: 15px;
  margin-left: 0rem;
  margin-top: 15px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.content {
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s;
  margin-left: 0; /* Shift content area to the right initially */
  margin-right: 15px;
  margin-left: 20rem;
  margin-top: 15px;
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
}

.function-top {
  background-color: transparent;
  color: #000;
  border-radius: 10px;
  margin-left: auto; /* Move buttons to the right */
  padding: 0.5em 1em;
  font-size: large;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  /* margin-bottom: -10%; */
  /* border: 1px solid #999; */
  font-family: "Product Sans Regular", sans-serif;
  display: flex; /* Use flexbox */
  justify-content: flex-end; /* Align items to the end (right) */
}

.function-top button {
  margin-left: 10px; /* Add spacing between buttons if needed */
}

/* print.css */
@media print {
  /* Add styles for printing here */
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #000;
    padding: 8px;
  }
}

.toggle {
  margin-left: 15rem;
  background-color: rgb(122, 199, 155);
  background-image: none;
  padding: 7px 10px;
  font-family: fontAwesome;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 3rem;
  height: 3.5rem;
  width: 3rem;
  color: #ced4da;
  border-color: rgb(122, 199, 155);;
}

.toggle.shift {
  position: relative; /* Add this line to establish a positioning context */
  z-index: 1; /* Add this line to bring the toggle above other elements */

  /* Your existing styles */
  margin-left: 0;
  background-color: rgb(122, 199, 155);
  background-image: none;
  padding: 7px 10px;
  font-family: fontAwesome;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 3rem;
  height: 3.5rem;
  width: 3rem;
  transition: margin-left 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.toggle:hover{
  color:rgb(122, 199, 155);
  background-color: white;
}
/* .maxText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px; 

.seeMoreLink {
  display: none;
  cursor: pointer;
} */

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  background-color: #7ac79b;
}
.page-item:first-child:hover {
  background-color: #7ac79b;
  border-top-right-radius: 2.25rem; /* Add right-side border radius */
  border-bottom-right-radius: 2.25rem; /* Add right-side border radius */
  border-top-left-radius: 2.25rem;
  border-bottom-left-radius: 2.25rem;
}

.page-item:last-child:hover {
  background-color: #7ac79b;
  border-top-right-radius: 2.25rem; /* Add right-side border radius */
  border-bottom-right-radius: 2.25rem; /* Add right-side border radius */
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2.25rem;
  border-bottom-left-radius: 2.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.page-item {
  background-color: #fafcfa;
}

.text-centers {
  font-size: x-large;
}
.close-btn-del {
  color: #000;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  box-sizing: border-box;
  margin-left: 10rem;
}
.add-btn-del {
  color: #000;
  background-color: #dd687b;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  box-sizing: border-box;
  margin-left: 0rem;
}

button.close {
  padding: 17px 20px;
  background-color: transparent;
  position: absolute;
  top: 1px;
  right: 12px;
}

.single-error {
  font-weight: bolder;
  font-size: large;
}
