.formRow {
    display: flex;
  }
  
  .inputt {
    width: 35%;
  }
  
  
.centerText{
  text-align: center; 
}

/* input[type='file'] {
  color: rgba(0, 0, 0, 0);
} */

.button {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  background: #007BFF;
  color: #FFF;
  padding: 8px 12px;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  background-color: #0069D9;
}

.tag-remove {
  font-size: 1.1em;
  color: white;
  height: fit-content;
  padding: 1px 7px 1px 7px;
  background-color: red;
  border: 2px solid gainsboro;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999;
  margin: -12px 0 0 -18px;
}

.tag-remove:hover {
  background-color: #ad0000;
}