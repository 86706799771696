.table-striped>thead>tr{
    border-bottom: 2px solid #0000;
}

.table-bordered thead th, .table-bordered thead td{
    border-bottom-color: #000 !important
}

.table-bordered td{
    max-width: 200px;
    overflow: hidden; /* Hide content that overflows the cell */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
}