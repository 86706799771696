.list-group {
  transition: width 0.3s ease-in-out;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin: 0 !important;
  margin-right: 10px !important;
}

.sideb {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 0%;
  position: fixed;
  width: 13rem;
}

#sidebar-wrapper {
  width: 14rem;
  height: 100%;
  position: fixed;
  top: 0;
  margin-right: 20rem;
  overflow-y: auto; /* Enable scrolling for the list */
  margin-left: 0px !important;
    margin-top: 0px !important;
}

.sideb.active {
  color: darkgreen; /* Adjust the last value (alpha) to control transparency */
  font-weight: bold;
  background-color: #303841;
  border-left: 5px solid green;
}

.sideBar-icon {
  margin-right: 10px;
}

.sideBar-icon svg {
  font-size: 24px; /* Make the icon bigger */
}

.list-group-item {
  color: aliceblue;
  background-color: #303841;
  border-left: 3px solid transparent;
  transition: border-left 0.3s ease;
  border: none;
  position: relative;
}

.list-group-item:hover {
  color: darkgreen;
  border-left: 5px solid green;
  background-color: #303841 !important; /* Match the background color of the list item and use !important to override any conflicting styles */
}

.list-group-item:hover::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #303841 !important; /* Match the background color of the list item and use !important to override any conflicting styles */
  color: darkgreen;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.list-group-item:hover::before:hover {
  opacity: 1;
}

/* Styling for the image */
.image {
  height: 200px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: inline-block;
  width: 11rem;
  border-top-right-radius: 100px;
}

/* Styling for the image itself (if needed) */
.image img {
  max-width: 100%;
  /* Ensure the image doesn't exceed the container width */
  max-height: 100%;
  /* Ensure the image doesn't exceed the container height */
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  width: 13rem;
}