
  .formRow {
    display: flex;
  }
  
  .inputt {
    width: 35%;
  }

  .greenButton{
    color:green;
    background-color: transparent;
    font-weight: bold;
  }

  .redButton{
    color: red;
    background-color: transparent;
    font-weight: bold;
  }