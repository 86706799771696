.card {
  border-radius: 10px;
  background-color: #55c769;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  vertical-align: middle;
  font-weight: bold;
  color: black;
  transition: transform 0.2s, z-index 0.2s, width 0.2s, margin 0.2s; 
}

.card:hover {
  color: #55c769;
  background-color: #7AC79B;
  transform: scale(1.1);
  z-index: 1;
}

.cardd {
  background-color: hsl(195, 100%, 99%);
  border-radius: 30px;
}

.all {
  background-color: #f7fafc;
}

.numb {
  color: rgb(8, 4, 4);
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.no {
  background-color: aliceblue;
}

.but {
  align-items: center;
  color: #ffffff;
  padding: 10px;
  margin-top: 17px;
  background-color: transparent;
  border: none;
  font-size: 22px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.but:hover {
  background-color: transparent;
}

.leftt {
  width: 50%;
  float: right;
}

.leftt {
  width: 50%;
  float: left;
}

.sec {
  background-color: #da737f;
}

.iconc {
  color: #da737f;
}

.icons{
justify-content: center;
}

.buttont {
  color: #464646;
}

.centered-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.circle-container {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid gray;
  background-color: white;
  color: #6c757d;
}

.chart-title {
  font-weight: bolder;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding-bottom: 10px;
  font-size: larger;
  text-align: center;
}

.horizontal-line {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: gray;
  margin-bottom: 10px;
}

.horizontal-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: gray;
}

.charts-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.charts-div .shadow-lg {
  margin: 15px;
}

.total-width {
  width: 95% !important;
}

.total-width .col-lg-4 {
  padding-left: 0px !important;
}

.total-width-last .col-lg-4 {
  padding: 0px;
}

@media (max-width: 508px) {
  .charts-div {
    display: flex;
    flex-direction: column;
  }
}

.card-text {
  color: black;
}