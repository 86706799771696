.carddd{
    margin-top: 50px;
    
}
.imagee{
    width: 60%;
    margin-left: 20%;

}
.in{
    border-bottom: solid;
}
.sp{
    /* align-items: center; */
    margin-top: 6px;
   


}